<template>
  <v-container fluid>
    <head-title text="Consulta de DTEs" :to="{ name: 'HomeDtes' }"></head-title>
    <v-row>
      <v-col cols-12 md="6">
        <business-name-field
          :returnId="false"
          v-model="companies_id"
        ></business-name-field>
      </v-col>
      <v-col cols-12 md="6">
        <v-select
          label="Sucursal"
          v-model="company_id"
          :items="companies_id"
          item-value="id"
          item-text="second_name"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-if="company_id != 0"
          :items="[
            { text: 'Por Mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
            { text: 'Por Período', value: 2 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
        <v-select
          v-else
          :items="[
            { text: 'Por Mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select
      ></v-col>
      <v-col cols="12" md="3" v-if="searchType === 1">
        <date-field label="Fecha" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="3" v-else-if="searchType === 0">
        <date-field label="Mes" v-model="date" type="month"></date-field>
      </v-col>
      <v-row v-else-if="searchType === 2 && company_id != 0">
        <v-col cols="12" md="6">
          <date-field label="De" v-model="date"></date-field>
        </v-col>
        <v-col cols="12" md="6">
          <date-field label="Hasta" v-model="until"></date-field>
        </v-col>
      </v-row>
      <v-col cols="12" sm="1" class="text-right">
        <v-btn
          class="mx-1"
          :disabled="loading"
          fab
          dark
          small
          color="primary"
          @click="getList()"
        >
          <v-icon dark> mdi-database-search </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <p class="subtitle-2 text-center ma-0 pa-0">Efectivo: {{ cash | currency }}</p>
      </v-col>
      <v-col cols="12" md="3">
        <p class="subtitle-2 text-center ma-0 pa-0">
          Depósitos: {{ deposit | currency }}
        </p>
      </v-col>
      <v-col cols="12" md="3">
        <p class="subtitle-2 text-center ma-0 pa-0">Créditos: {{ credit | currency }}</p>
      </v-col>
      <v-col cols="12" md="3">
        <p class="subtitle-2 text-center ma-0 pa-0">TOTAL: {{ total | currency }}</p>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      disable-pagination
      disable-sort
      class="elevation-1"
    >
      <template v-slot:[`item.correlativo_interno`]="{ item }">
        <v-btn text color="primary" @click="displayOrder(item.correlativo_interno)">
          {{ item.correlativo_interno }}
        </v-btn>
      </template>
      <template v-slot:[`item.dte`]="{ item }">
        {{ item.serie }} - {{ item.numero }}
      </template>
      <template v-slot:[`item.fecha_emision`]="{ item }">{{
        item.fecha_emision | time
      }}</template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-icon v-if="item.estado == 'REGISTRADO'" small color="green darken-2">
          mdi-check-outline
        </v-icon>
        <v-icon v-else-if="item.estado == 'EMITIDO'" small color="red darken-2">
          mdi-close-outline
        </v-icon>
        <v-icon v-else-if="item.estado == 'ANULADO'" small color="red darken-2">
          mdi-minus-circle-outline
        </v-icon>
        <v-icon v-else-if="item.estado == 'CANCELADO'" small color="red darken-2">
          mdi-delete-outline
        </v-icon>
      </template>
      <template v-slot:[`item.gran_total`]="{ item }">{{
        item.gran_total | currency
      }}</template>
    </v-data-table>
    <dte
      v-if="dteId != null"
      :dialog="dteDialog"
      :dteId="dteId"
      @close="
        dteDialog = false;
        dteId = null;
      "
    ></dte>
  </v-container>
</template>
<script>
import requests from "@/services/requests";

export default {
  components: {
    Dte: () => import("@/components/dtes/Dte"),
    DateField: () => import("@/components/fields/DateField.vue"),
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    BusinessNameField: () => import("@/components/fields/BusinessNameField"),
  },
  data: () => ({
    until: "",
    searchType: 1,
    // show Dte
    dteDialog: false,
    dteId: null,
    company_id: undefined,
    date: null,
    credit: 0,
    cash: 0,
    deposit: 0,
    total: 0,
    companies_id: [],
    business_id: undefined,
    //status
    showDatePicker: false,
    loading: false,
    items: [],
    headers: [
      { text: "Correlativo Interno", value: "correlativo_interno" },
      { text: "Documento", value: "dte" },
      { text: "Tipo", value: "tipo_dte" },
      { text: "Sucursal", value: "_sucursal" },
      { text: "Hora", value: "fecha_emision" },
      { text: "Cliente", value: "nombre_receptor", width: 200 },
      { text: "Usuario", value: "_user" },
      { text: "Estado", value: "estado", align: "center" },
      { text: "Pago", value: "tipo_pago", align: "center" },
      { text: "Total", value: "gran_total", align: "end" },
    ],
  }),
  mounted() {
    this.date = new Date().toISOString().substr(0, 10);
  },
  methods: {
    getList() {
      this.loading = true;
      if (this.business_id == undefined) {
        this.$toasted.global.info({ message: "Debe seleccionar una empresa" });
        this.loading = false;
        return;
      }
      if (this.company_id == undefined) {
        this.$toasted.global.info({ message: "Debe seleccionar una sucursal" });
        this.loading = false;
        return;
      }
      var uri = "/api/dtes/";
      if (this.company_id > 0) {
        uri = uri + "?sucursal_id=" + this.company_id + "&business=" + this.business_id;

        if (this.searchType === 1) {
          uri += "&date=" + this.date;
        } else if (this.searchType === 0) {
          uri +=
            "&year=" + this.date.substring(0, 4) + "&month=" + this.date.substring(5, 7);
        } else if (this.searchType === 2) {
          if (this.until === null) {
            this.$toasted.global.info({
              message: "Seleccione la fecha",
            });
            return;
          } else {
            uri += "&date=" + this.date + "&until=" + this.until;
          }
        }
      } else if (this.company_id === 0) {
        if (this.searchType === 1) {
          uri = uri + "?date=" + this.date + "&business=" + this.business_id;
        } else if (this.searchType === 0) {
          uri =
            uri +
            "?year=" +
            this.date.substring(0, 4) +
            "&month=" +
            this.date.substring(5, 7) +
            "&business=" +
            this.business_id;
        }
      } else {
        this.$toasted.global.info({
          message: "Falló al seleccionar una sucursal",
        });
      }
      requests.get(uri).then((response) => {
        if (response.status == 200) {
          this.items = response.data;
          this.sumTipoPago();
        } else {
          console.log(response);
        }
        this.loading = false;
      });
    },
    sumTipoPago() {
      this.credit = 0;
      this.deposit = 0;
      this.cash = 0;
      this.total = 0;
      this.items.forEach((obj) => {
        if (
          obj.estado === "REGISTRADO" &&
          (obj.tipo_dte === "FACT" || obj.tipo_dte === "FCAM")
        ) {
          if (obj.tipo_pago === "EFECTIVO") {
            this.cash += obj.gran_total;
          }
          if (obj.tipo_pago === "CRÉDITO") {
            this.credit += obj.gran_total;
          }
          if (obj.tipo_pago === "DEPÓSITO") {
            this.deposit += obj.gran_total;
          }
          this.total += obj.gran_total;
        }
      });
    },
    displayOrder(id) {
      this.dteId = id;
      this.dteDialog = true;
    },
  },
  watch: {
    companies_id: function () {
      if (this.companies_id.length != 0) {
        var valid = this.companies_id.find((el) => el.id == 0);
        if (valid == undefined) {
          this.companies_id.push({
            id: 0,
            second_name: "TODAS",
            business_name_id: this.companies_id[0].business_name_id,
          });
        }
        this.business_id = this.companies_id[0].business_name_id;
      }
    },
    searchType(newValue) {
      if (newValue === 1) {
        this.date = new Date().toISOString().substring(0, 10);
      }
      if (newValue === 0) {
        this.date = new Date().toISOString().substring(0, 7);
      }
      if (newValue === 2) {
        this.date = new Date().toISOString().substring(0, 10);
        this.until = new Date().toISOString().substring(0, 10);
      }
    },
  },
};
</script>
